import { graphql } from 'gatsby';
import React from 'react';
import HomeBanner from '@connected/HomeBanner';
import Sections from '@connected/Sections';
import SEO from '@connected/SEO';
const IndexPage = ({ data: { allKontentItemHome: connection }, }) => {
    if (!connection?.nodes || !connection?.nodes.length) {
        return null;
    }
    const homeData = connection.nodes[0];
    if (!homeData) {
        return null;
    }
    const bannerData = homeData.elements?.banner
        .linked_items?.[0];
    return (<>
      <SEO {...homeData.metadata} image={homeData.elements?.og_image?.value?.[0]?.url} url="/"/>
      {bannerData && <HomeBanner data={bannerData}/>}
      <Sections {...homeData.elements?.sections__sections}/>
    </>);
};
export const query = graphql `
  query HomeQuery {
    allKontentItemHome(limit: 1) {
      nodes {
        ...SEOFragment
        elements {
          og_image: metadata__page_image {
            value {
              url
            }
          }
          banner {
            linked_items {
              ... on KontentItemHomeBanner {
                elements {
                  content {
                    value
                  }
                  cta__cta_event_name {
                    value
                  }
                  cta__cta_link__external {
                    value
                  }
                  cta__cta_link__internal {
                    linked_items {
                      ... on NodeWithUrl {
                        url
                      }
                    }
                  }
                  cta__cta_text {
                    value
                  }
                  image {
                    value {
                      fluid(maxWidth: 800) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                  title {
                    value
                  }
                }
              }
            }
          }
          sections__sections {
            linked_items {
              ...LinkedItemsFragment
            }
          }
          standard_content__title {
            value
          }
          standard_content__summary {
            value
          }
        }
      }
    }
  }
`;
export default IndexPage;
