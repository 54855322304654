import { motion } from 'framer-motion';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import formatTitle from '@utils/formatTitle';
const HeroBanner = ({ title, content, ctaEventName, ctaIsExternal, ctaText, ctaTo, image, }) => {
    const containerRef = useRef(null);
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const contentProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const ctaProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    return (<div ref={containerRef} className="hero-banner hero-banner--small site-wide">
      <Grid layout={GridLayout.FULL_INDENT}>
        <GridColumn>
          <div className="hero-banner__inner">
            <div className="hero-banner__column">
              <motion.h1 className="hero-banner__title" {...titleProps}>
                {formatTitle(title)}
              </motion.h1>
              <motion.p className="hero-banner__content" {...contentProps}>
                {content}
              </motion.p>
              {ctaText && ctaTo && (<motion.div className="hero-banner__cta" {...ctaProps}>
                  <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.OrangeSolid}/>
                </motion.div>)}
            </div>
          </div>
        </GridColumn>
      </Grid>
      <div className="hero-banner__image">
        <Image className="hero-banner__media" alt={title} loading="eager" fluid={image} imgStyle={{ objectFit: 'contain' }}/>
      </div>
    </div>);
};
export default HeroBanner;
